import React, { useState } from "react";
import {
  Box,
  Typography,
  Collapse,
  Fade,
  Button,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import { StyledEnquiryTableCell } from "./StyledEnquiryTableCell";
import { formatDate, truncateString } from "../../utils/textFormat";
import { BookingsCollapse } from "./BookingsCollapse";
import {
  PAYMENT_STATUS,
  SAVING_STATUS,
  TRIP_STATUS,
} from "../../constants/constant";
import { useSelector, useDispatch } from "react-redux";
import { createRemainingPaymentOrder } from "../../api/Customer/payment";
import { DialogBtn } from "../Common/DialogBtn";
import { loadScript } from "../../utils/razorpay";
import {
  createTransaction,
  patchToggleFavourite,
} from "../../api/Customer/trips";
import { setBookingStatusTracking } from "../../redux/slices/customerSlice";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

function CreateTripsTable({
  row,
  openRow,
  setOpenRow,
  index,
  setIsToggleFavouriteRequested,
  isToggleFavouriteRequested,
  handleOpenToast,
}) {
  const dispatch = useDispatch();

  const customerId = useSelector((state) => state.customer.id);
  const customerName = useSelector((state) => state.customer.name);
  const customerEmail = useSelector((state) => state.customer.email);
  const customerPhone = useSelector((state) => state.customer.phone);

  const navigate = useNavigate();

  const isOpen = openRow === index;
  const handleRowClick = () => {
    setOpenRow((prevOpenRow) => {
      return prevOpenRow === index ? null : index;
    });
  };

  const rowStyles = {
    backgroundColor:
      index === openRow || row.status === "Offer Received"
        ? "#E9F2F4"
        : "#FFFFFF",
    borderBottom: 0,
    fontWeight: 700,
    color: "#6d6d6d",
  };
  const paymentStatusStyles = {
    color: getPaymentStatusColor(row.customerPaymentStatus),
  };
  const tripStatusStyles = {
    color: getTripStatusColor(row.tripStatus),
  };

  const handleCreateTransaction = async (
    paymentId,
    tripId,
    customerEnquiryId
  ) => {
    if (!customerId || !paymentId || !tripId || !customerEnquiryId) {
      handleOpenToast(
        `Error creating transaction. Please apply for refund`,
        "error"
      );
      return;
    }
    let transactionData = {
      paymentId: paymentId,
      tripId: tripId,
      customerEnquiryId: customerEnquiryId,
      senderId: String(customerId),
      receiverId: "LE_PLATFORM",
      isPaymentDoneThroughLEPlatform: true,
      transactionStatus: "SUCCESS",
      paymentType: "RECEIVED",
      userType: "customer",
    };
    try {
      const response = await createTransaction(transactionData);
      if (response.status === 200) {
        handleOpenToast("Transaction created successfully.", "success");
        dispatch(setBookingStatusTracking(`transaction_created`));
      } else {
        //TODO: Need to create refund api
        // payment done but transaction not created
        // refund code
        handleOpenToast(
          `Error creating transaction.Please apply for refund`,
          "error"
        );
      }
    } catch (e) {
      handleOpenToast(
        `Error creating transaction.Please apply for refund`,
        "error"
      );
    }
  };

  async function displayRazorpay() {
    try {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      const orderData = {
        amount: row?.customerAmounts?.balanceReceivable,
      };

      const result = await createRemainingPaymentOrder(row.id, orderData);
      if (!result) {
        alert("Server error. Are you online?");
        return;
      }

      const { amount, id: order_id, currency } = result.data.data;
      // Note : 1)unable to add upi option due to test sandbox , it will be available in live payment
      //        2)host loadeasy logo and add image as url
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
        amount: amount.toString(),
        currency: currency,
        name: "LoadEazy",
        description: "Test Transaction",
        image:
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAcBAMAAABi/9neAAAAG1BMVEUAAAAOgEc5OTk6OjoMgEQzMzMMf0QzMzP////9dtj5AAAABnRSTlMAEhIj7u4z0rXDAAAAAWJLR0QIht6VegAAAEVJREFUGNNjYEAFoaEBLC4wwMBQXl7AlgYDpHMZBWEAzEW2CMhlgVuDopccLiPcGnwW0dpHpaFwAOIiARK5xsZGSnDAAAB8VWucFCeHkwAAAABJRU5ErkJggg==",
        order_id: order_id,
        handler: async function (response) {
          var razorpayPaymentId = response?.razorpay_payment_id;
          await handleCreateTransaction(
            razorpayPaymentId,
            row?.tripId,
            row?.customerEnquiryId
          );
        },
        prefill: {
          name: customerName ? customerName : "",
          email: customerEmail ? customerEmail : "",
          contact: customerPhone ? customerPhone : "",
        },
        notes: {
          address: "LoadEazy office address",
        },
        theme: {
          color: "#0C7F44",
        },
      };

      const rzp = new window.Razorpay(options);

      rzp.open();
    } catch (error) {
      alert("Razorpay SDK failed to load. Are you online?");
    }
  }

  const toggleFavourite = async (e, tripId) => {
    e.stopPropagation();
    if (isToggleFavouriteRequested === SAVING_STATUS.SENDING) {
      return;
    }
    try {
      setIsToggleFavouriteRequested(SAVING_STATUS.SENDING);
      const response = await patchToggleFavourite(customerId, tripId);
      if (response.status === 200) {
        const details = response?.data.data;
        setIsToggleFavouriteRequested(SAVING_STATUS.SAVED);
        const uniqueTimestamp = Date.now();
        dispatch(
          setBookingStatusTracking(
            `${tripId}-added-to-favourite-${uniqueTimestamp}`
          )
        );
      }
    } catch (error) {
      setIsToggleFavouriteRequested(SAVING_STATUS.FAILED);
    }
  };

  const isPaymentSettlementDeadlineExpired = () => {
    const currentTime = new Date().getTime();
    const expiryTime = new Date(
      row?.customerPaymentSettlementDeadline
    ).getTime();

    const timeRemaining = Math.max(0, expiryTime - currentTime);
    const remainingSeconds = Math.floor(timeRemaining / 1000);
    return remainingSeconds === 0;
  };

  const getActionButtonStyle = (status) => {
    if (
      status === PAYMENT_STATUS.PARTIALLY_PAID &&
      isPaymentSettlementDeadlineExpired()
    )
      return {
        color: "#FFFFFF",
        backgroundColor: "#FF3F3F",
        border: "1px solid transparent",
        padding: "4px 12px",
        "&:hover": {
          color: "#000000",
          border: "1px solid #000000",
        },
      };
    else if (
      status === PAYMENT_STATUS.PARTIALLY_PAID ||
      status === PAYMENT_STATUS.UNPAID
    )
      return {
        color: "#FFFFFF",
        backgroundColor: "#0C7F44",
        padding: "4px 12px",
        "&:hover": {
          color: "#000000",
          border: "1px solid #000000",
        },
      };
    else if (status === PAYMENT_STATUS.PAID)
      return {
        color: "#333333",
        backgroundColor: "#FFFFFF",
        border: "1px solid #333333",
        padding: "4px 12px",
      };
    else
      return {
        display: "none",
      };
  };

  const renderActionBtn = (paymentStatus, row) => {
    if (
      paymentStatus === PAYMENT_STATUS.UNPAID ||
      paymentStatus === PAYMENT_STATUS.PARTIALLY_PAID
    ) {
      return (
        <DialogBtn
          onClick={(e) => {
            e.stopPropagation();
            displayRazorpay();
          }}
          title={getPaymentBtnTitle(paymentStatus)}
          buttonStyle={getActionButtonStyle(paymentStatus)}
        />
      );
    } else if (
      paymentStatus === PAYMENT_STATUS.PAID &&
        row?.tripStatus === TRIP_STATUS.TRIP_COMPLETED
    ) {
      return (
        <DialogBtn
          onClick={(e) => {
            e.stopPropagation();
            navigate("/rebook", {
              state: {
                tripId: row?.id,
                customerEnquiryId: row?.customerEnquiryId,
                customerId: customerId,
              },
            });
          }}
          title={getPaymentBtnTitle(paymentStatus)}
          buttonStyle={getActionButtonStyle(paymentStatus)}
        />
      );
    } else {
      <></>;
    }
  };
  return (
    <>
      <StyledTableRow
        key={index + 1}
        onClick={handleRowClick}
        isRowOpen={index === openRow}
      >
        <StyledEnquiryTableCell sx={rowStyles}>
          <Box display="flex" alignItems="center">
            {row?.isFavourite ? (
              <StarIcon
                onClick={(e) => toggleFavourite(e, row?.id)}
                sx={{ cursor: "pointer", color: "orange", fontSize: 24 }}
              />
            ) : (
              <StarBorderIcon
                onClick={(e) => toggleFavourite(e, row?.id)}
                sx={{ cursor: "pointer", fontSize: 24 }}
              />
            )}
            <Typography sx={{ paddingLeft: 2, fontSize: 14, fontWeight: 700 }}>
              {truncateString(row?.tripId, 15)}
            </Typography>
          </Box>
        </StyledEnquiryTableCell>
        <StyledEnquiryTableCell sx={{ ...rowStyles, textAlign: "center" }}>
          {formatDate(row?.tripDate, "DD-MM-YYYY")}
        </StyledEnquiryTableCell>
        <StyledEnquiryTableCell sx={{ ...rowStyles, textAlign: "center" }}>
          {truncateString(row?.route, 30)}
        </StyledEnquiryTableCell>
        <StyledEnquiryTableCell sx={{ ...rowStyles, ...tripStatusStyles }}>
          {getTripStatus(row?.tripStatus)}
        </StyledEnquiryTableCell>
        <StyledEnquiryTableCell sx={rowStyles}>
          ₹ {row?.customerAmounts?.finalAmount}
        </StyledEnquiryTableCell>
        <StyledEnquiryTableCell sx={rowStyles}>
          ₹ {row?.customerAmounts?.amountReceived}
        </StyledEnquiryTableCell>
        <StyledEnquiryTableCell sx={rowStyles}>
          {" "}
          ₹ {row?.customerAmounts?.balanceReceivable}
        </StyledEnquiryTableCell>
        <StyledEnquiryTableCell sx={{ ...rowStyles, ...paymentStatusStyles }}>
          {getPaymentStatus(row?.customerPaymentStatus)}
        </StyledEnquiryTableCell>
        {getTripStatus(row?.tripStatus) === 'Cancelled' ? null:<StyledEnquiryTableCell sx={rowStyles}>
          {renderActionBtn(row?.customerPaymentStatus, row)}
        </StyledEnquiryTableCell>}
      </StyledTableRow>
      <TableRow sx={{ width: "100%" }}>
        <StyledEnquiryTableCell
          style={{
            padding: 0,
            border: index === openRow ? "1px solid #6D6D6D" : "none",
          }}
          colSpan={9}
        >
          <Fade in={isOpen} timeout={1000}>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <BookingsCollapse index={index} trip={row} />
            </Collapse>
          </Fade>
        </StyledEnquiryTableCell>
      </TableRow>
    </>
  );
}

export const TripsTable = ({
  trips,
  actionFilter,
  paymentStatusFilter,
  tripStatusFilter,
  openRow,
  setOpenRow,
  handleOpenToast,
}) => {
  const [isToggleFavouriteRequested, setIsToggleFavouriteRequested] =
    useState("");
  let filteredRows = trips;

  if (actionFilter.length > 0) {
    filteredRows = filteredRows.filter((row) =>
      actionFilter.includes(
        getPaymentBtnTitle(row?.customerPaymentStatus).toLowerCase()
      )
    );
  }

  if (paymentStatusFilter.length > 0) {
    filteredRows = filteredRows.filter((row) =>
      paymentStatusFilter.includes(
        getPaymentStatus(row?.customerPaymentStatus).toLowerCase()
      )
    );
  }

  if (tripStatusFilter.length > 0) {
    filteredRows = filteredRows.filter((row) =>
      tripStatusFilter.includes(getTripStatus(row?.tripStatus).toLowerCase())
    );
  }

  return trips.length ? (
    <TableBody>
      {filteredRows.map((row, index) => (
        <CreateTripsTable
          key={index}
          row={row}
          openRow={openRow}
          setOpenRow={setOpenRow}
          index={index}
          setIsToggleFavouriteRequested={setIsToggleFavouriteRequested}
          isToggleFavouriteRequested={isToggleFavouriteRequested}
          handleOpenToast={handleOpenToast}
        />
      ))}
    </TableBody>
  ) : (
    <TableRow sx={{ background: "#EAEAEA" }}>
      <TableCell
        colSpan={12}
        align="center"
        sx={{ fontWeight: 600, color: "#333" }}
      >
        No Results Found.
      </TableCell>
    </TableRow>
  );
};

const getPaymentStatusColor = (status) => {
  if (status === PAYMENT_STATUS.PARTIALLY_PAID) return "#6D6D6D";
  else return "#0C7F44";
};
const getTripStatusColor = (status) => {
  if (status === "Canceled") return "#B8B8B8";
  else return "#6D6D6D";
};

const getTripStatus = (status) => {
  if (!status) {
    return "--";
  }

  const upcomingStatusArray = [
    TRIP_STATUS.BOOKING_ACCEPTED,
    TRIP_STATUS.DRIVER_AT_PICKUP_LOCATION,
    TRIP_STATUS.MATERIAL_LOADED,
  ];

  const cancelledStatusArray = [
    TRIP_STATUS.CANCELLED_BY_ADMIN,
    TRIP_STATUS.CANCELLED_BY_CUSTOMER,
    TRIP_STATUS.CANCELLED_BY_TRANSPORTER,
  ];

  if (upcomingStatusArray.includes(status)) {
    return "Upcoming";
  } else if (status === TRIP_STATUS.TRUCK_IN_TRANSIT) {
    return "Intransit";
  } else if (status === TRIP_STATUS.TRIP_COMPLETED) {
    return "Completed";
  } else if (status === TRIP_STATUS.DRIVER_AT_DROPOFF_LOCATION) {
    return "Delivered";
  } else if (cancelledStatusArray.includes(status)) {
    return "Cancelled";
  } else {
    return "--";
  }
};

const getPaymentStatus = (status) => {
  if (status === PAYMENT_STATUS.PARTIALLY_PAID) {
    return "Partially Paid";
  } else if (status === PAYMENT_STATUS.PAID) {
    return "Paid";
  } else if (status === PAYMENT_STATUS.UNPAID) {
    return "Unpaid";
  }
};
const getPaymentBtnTitle = (status) => {
  return status === PAYMENT_STATUS.PAID ? "Rebook" : "Pay Now";
};

const StyledTableRow = styled(TableRow)`
  border: ${(props) => (props.isRowOpen ? "1px solid #6D6D6D" : "none")};
  cursor: pointer;
`;
